import StartIcon from "./icons/startIcon";
import QuoteIconNormal from "./icons/quoteIconNormal";
import QuoteIconActive from "./icons/quoteIconActive";
import CompleteIcon from "./icons/completeIcon";
import { QuoteInfoType } from "./constants/quoteInfoType";
import AboutIconActive from "./icons/aboutIconActive";
import AboutIconNormal from "./icons/aboutIconNormal";
import useWindowDimensions from "./../../pages/DebitPage/useWindowDimensions";

export const AutoTab = (props) => {
  const { tabs } = props;
  const { width, height } = useWindowDimensions();
  const isSmallMobile = height < 750;
  const isMobile = width < 750 || isSmallMobile;

  const iconSize = isMobile ? (isSmallMobile ? 20 : 30) : 45;

  const getIcon = (type, isActive) => {
    let icon = null;

    switch (type) {
      case QuoteInfoType.Start:
        icon = <StartIcon width={iconSize} height={iconSize} />;
        break;
      case QuoteInfoType.About:
        icon = isActive ? (
          <AboutIconActive width={iconSize} height={iconSize} />
        ) : (
          <AboutIconNormal width={iconSize} height={iconSize} />
        );
        break;
      case QuoteInfoType.Quote:
        icon = isActive ? (
          <QuoteIconActive width={iconSize} height={iconSize} />
        ) : (
          <QuoteIconNormal width={iconSize} height={iconSize} />
        );
        break;
      default:
        break;
    }

    return icon;
  };

  return (
    <div
      style={{
        width: "auto",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        gap: "40px",
      }}
    >
      {tabs?.map((item, index) => {
        const isActive = props.sectionIndex === index;
        const isComplete = index < props.sectionIndex;

        return (
          <div
            key={index}
            style={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: isMobile ? "column" : "row",
              gap: "5px",
            }}
            onClick={() => {
              props.onTabSelected(index);
            }}
          >
            {isComplete ? (
              <CompleteIcon width={iconSize} height={iconSize} />
            ) : (
              getIcon(item.type, isActive, isMobile)
            )}{" "}
            <p
              style={{
                fontSize: isMobile ? 15 : 20,
              }}
            >
              {isMobile ? item.title.split(" ")[0] : item.title}
            </p>
          </div>
        );
      })}
    </div>
  );
};
