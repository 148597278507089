import './Footer.scss';
import { useNavigate } from "react-router-dom"
import useDetectKeyboardOpen from "use-detect-keyboard-open";

export default function Navbar({ className = "" }) {
    const navigate = useNavigate()
    const isKeyboardOpen = useDetectKeyboardOpen();

    return (
        <footer style={{
            display: isKeyboardOpen ? "none": "flex"
        }} className={"page-footer " + className}>
            <div onClick={() => navigate("/terms")}>Terms</div>
            <span>|</span>
            <div onClick={() => navigate("/privacy")}>Privacy</div>
            <span>|</span>
            <a href="mailto:contact@simpledebtfix.com">Contact</a>
        </footer>
    )
}