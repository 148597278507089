import React from "react"
import { useTitle } from 'react-use'
import TermsData from "./data.json"
import Footer from "../../components/Footer/Footer"
import "./TermsPage.scss"
import Navbar from "../../components/Navbar/Navbar"

export default function TermsPage() {
    useTitle("Terms of Use  - Simple Debt Fix")

    return (
        <>
            <Navbar />
            <div className="terms-page">
                <div className="terms-container">
                    <div className="title">{TermsData.title}</div>
                    <div className="sub-title">{TermsData.subTitle}</div>
                    <div className="page-content">
                        <div className="desc">
                            {TermsData?.description.map((desc, desIndex) => (
                                <p key={"des_" + desIndex} dangerouslySetInnerHTML={{ __html: desc }}></p>
                            ))}
                        </div>
                        {TermsData?.terms.map((term, index) => (
                            <div className="terms" key={index}>
                                <h3 className="term-header">
                                    <span style={{ marginRight: "4px" }}> {index + 1}.</span>{" "}
                                    {term.header}
                                </h3>
                                {term?.paragraph?.map((text, termIndex) => (
                                    <p className="term-text" key={"term_" + termIndex} dangerouslySetInnerHTML={{ __html: text }}></p>
                                ))}
                                {term?.paragraphSubs?.map((text, termIndex) => (
                                    <p className="term-text" style={{ marginLeft: text.includes("- ") ? 20 : "", lineHeight: "100%" }} key={"term_" + termIndex}>{text}</p>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}