import { HashRouter, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage";
import DebtPage from "./pages/DebtPage/DebtPage";
import DebitPage from "./pages/DebitPage/DebitPage";
import PrivacyPage from "./pages/PrivacyPage/PrivacyPage";
import CreditPage from "./pages/CreditPage/CreditPage";
import TermsPage from "./pages/TermsPage/TermsPage";
import NoOfferPage from "./pages/NoOfferPage/NoOfferPage";

export default function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/repair-credit" element={<CreditPage />} />
        <Route path="/fix-debt" element={<DebtPage />} />
        <Route path="/debit" element={<DebitPage />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/terms" element={<TermsPage />} />
        <Route path="/no-offer" element={<NoOfferPage />} />
        <Route path="*" element={<HomePage />} />
      </Routes>
    </HashRouter>
  );
}
