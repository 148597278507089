import "./HomePage.scss"
import { useNavigate } from "react-router-dom"
import Footer from "../../components/Footer/Footer"
import Navbar from "../../components/Navbar/Navbar"

export default function HomePage() {
    const navigate = useNavigate()

    return (
        <>
            <Navbar />
            <div className="home-page">
                <div className="flex-group" >
                    <div className="label-1">SimpleDebtFix.com <br />For your debt and credit needs!</div>
                    <div className="banner-1">
                        <button
                            onClick={() => { navigate("/fix-debt") }}
                            className="button-1"
                            type="submit">
                            Fix your debt
                        </button>
                    </div>
                    <div className="banner-2" >
                        <button
                            onClick={() => { navigate("/repair-credit") }}
                            className="button-2"
                            type="submit">
                            Repair your credit
                        </button>
                    </div>
                </div>
            </div>

            <Footer className="footer" />
        </>
    )
}