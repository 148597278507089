import styles from './Navbar.module.css';
import { useState } from 'react'
import { useNavigate } from "react-router-dom"

export default function Navbar() {
    const navigate = useNavigate()

    // adding the states 
    const [isActive, setIsActive] = useState(false);


    //add the active class
    const toggleActiveClass = () => {
        setIsActive(!isActive);
    };


    //clean up function to remove the active class
    const removeActive = () => {
        setIsActive(false)
    }

    return (
        <header className="App-header">

            <nav className={`${styles.navbar}`}>
                <button onClick={() => navigate("/")} className={`${styles.logo}`}>SimpleDebtFix.com</button>

                <ul className={`${styles.navMenu} ${isActive ? styles.active : ''}`}>
                    <li onClick={removeActive}>
                        <button onClick={() => navigate("/repair-credit")} className={`${styles.navLink}`}>Repair your credit</button>
                    </li>
                    <li onClick={removeActive}>
                        <button onClick={() => navigate("/fix-debt")} className={`${styles.navLink}`}>Fix your debt</button>
                    </li>
                </ul>

                <div className={`${styles.hamburger} ${isActive ? styles.active : ''}`} onClick={toggleActiveClass}>
                    <span className={`${styles.bar}`}></span>
                    <span className={`${styles.bar}`}></span>
                    <span className={`${styles.bar}`}></span>
                </div>
            </nav>

        </header>
    )
}