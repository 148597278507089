import React from "react";

function QuoteIconNormal(props) {
  return (
    <svg width={45} height={45} viewBox="0 0 42 42" fill="none" {...props}>
      <path
        opacity="0.5"
        d="M21 -9.17939e-07C32.598 -1.4249e-06 42 9.40202 42 21C42 32.598 32.598 42 21 42C9.40202 42 -4.10975e-07 32.598 -9.17939e-07 21C-1.4249e-06 9.40202 9.40202 -4.10975e-07 21 -9.17939e-07ZM21 39.48C31.2062 39.48 39.48 31.2062 39.48 21C39.48 10.7938 31.2062 2.52 21 2.52C10.7938 2.52 2.52 10.7938 2.52 21C2.52 31.2062 10.7938 39.48 21 39.48Z"
        fill="#D9D9D9"
      />
      <path
        opacity="0.5"
        d="M25.2 17.9431V17.5C25.2 15.505 22.3628 14 18.6 14C14.8373 14 12 15.505 12 17.5V20.6818C12 22.3435 13.9688 23.664 16.8 24.0593V24.5C16.8 26.495 19.6373 28 23.4 28C27.1628 28 30 26.495 30 24.5V21.3182C30 19.6716 28.0935 18.3495 25.2 17.9431ZM15.6 22.501C14.1308 22.0659 13.2 21.3492 13.2 20.6818V19.5626C13.812 20.0224 14.6318 20.3931 15.6 20.642V22.501ZM21.6 20.642C22.5683 20.3931 23.388 20.0224 24 19.5626V20.6818C24 21.3492 23.0693 22.0659 21.6 22.501V20.642ZM20.4 26.3192C18.9308 25.8841 18 25.1674 18 24.5V24.1683C18.1973 24.1762 18.3968 24.1818 18.6 24.1818C18.891 24.1818 19.1753 24.1715 19.4543 24.154C19.7642 24.2717 20.0799 24.3715 20.4 24.4531V26.3192ZM20.4 22.7699C19.8041 22.8633 19.2024 22.9098 18.6 22.9091C17.9976 22.9098 17.3959 22.8633 16.8 22.7699V20.8775C17.3968 20.96 17.9981 21.0009 18.6 21C19.2019 21.0009 19.8032 20.96 20.4 20.8775V22.7699ZM25.2 26.5881C24.0064 26.7737 22.7936 26.7737 21.6 26.5881V24.6909C22.1966 24.776 22.798 24.8185 23.4 24.8182C24.0019 24.8191 24.6032 24.7782 25.2 24.6957V26.5881ZM28.8 24.5C28.8 25.1674 27.8693 25.8841 26.4 26.3192V24.4602C27.3683 24.2112 28.188 23.8406 28.8 23.3808V24.5Z"
        fill="black"
      />
    </svg>
  );
}

export default QuoteIconNormal;
