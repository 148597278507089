import { useTitle } from "react-use";
import Footer from "../../components/Footer/Footer";
import styled from "styled-components";
import { AutoTab } from "../../components/HeaderTab/AutoTab";
import { QuoteInfoType } from "../../components/HeaderTab/constants/quoteInfoType";
import { useState } from "react";
import validator from "validator";
import US_STATES from "./us_states.json";
import { Link } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import useWindowDimensions from "./useWindowDimensions";

const StyledHeader = styled.div`
  background-color: #fff;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
  height: 150px;

  a {
    color: "#0B3C49";
    cursor: pointer;
    border: none;
    background-color: transparent;
    font-weight: 700;
    font-size: 32px;

    @media (max-width: 750px) or ((min-height: 800px) and (min-width: 750px)) or ((min-height: 500px)) {
      font-size: 25px;
    }
  }

  @media (max-height: 750px) {
    height: 60px;
    a {
      display: none;
    }
  }
`;

const StyledMain = styled.div`
  background-color: #f0faf5;
  height: calc(100vh - 150px);
  min-height: calc(100vh - 150px);
  width: 100%;
  @media (max-width: 750px) or ((min-height: 800px) and (min-width: 750px)) or ((min-height: 500px)) {
    height: auto;
  }
`;

const StyledMainSection = styled.div`
  align-items: center;
  display: flex;
  justify-content: start;
  flex-direction: column;
  padding-top: 50px;
  width: 100%;
  height: 100%;
  @media (max-width: 750px) or ((min-height: 800px) and (min-width: 750px)) or ((min-height: 500px)) {
    padding-top: 30px;
  }
`;

const StyledQuestionDetail = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 50px 50px 30px 50px;
  background-color: #ffffff;
  border-radius: 10px;
  border: 0.5px solid #0b3c4950;
  width: 90%;
  max-width: 775px;
  min-height: 368px;
  color: #111111;
  gap: 10px;
  font-size: 16px;

  @media (max-width: 750px) or ((min-height: 800px) and (min-width: 750px)) or ((min-height: 500px)) {
    padding: 30px;
    font-size: 14px;
  }

  h3 {
    font-size: 30px;
    line-height: 100%;
    @media (max-width: 750px) or ((min-height: 800px) and (min-width: 750px)) or ((min-height: 500px)) {
      font-size: 20px;
    }

    @media (max-height: 750px) {
      font-size: 15px;
    }
  }

  .subtitle {
    margin-bottom: 25px;
    opacity: 0.75;
    font-size: 16px;

    @media (max-width: 750px) or ((min-height: 800px) and (min-width: 750px)) or ((min-height: 500px)) {
      font-size: 14px;
    }
  }

  .input-custom input {
    background-color: #f0faf5;
    height: 55px;
    border: 0.5px solid #0b3c4950;
    border-radius: 7px;
    margin-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 600;
    font-size: 16px;

    @media (max-width: 750px) or ((min-height: 800px) and (min-width: 750px)) or ((min-height: 500px)) {
      height: 40px;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 14px;
      width: 100%;
    }
  }

  .inputGroup-options {
    display: flex;
    gap: 25px;

    input[type="button"] {
      width: 135px;
      padding-left: 0px;
      cursor: pointer;
    }

    @media (max-width: 750px) or ((min-height: 800px) and (min-width: 750px)) or ((min-height: 500px)) {
      gap: 5px;
    }
  }

  .inputGroup-select {
    display: flex;
    gap: 10px;
    margin-top: -10px;
    flex-direction: column;
    margin-bottom: 20px;

    @media (max-width: 750px) or ((min-height: 800px) and (min-width: 750px)) or ((min-height: 500px)) {
      margin-top: 0px;
      gap: 5px;
    }

    input[type="button"] {
      width: 635px;
      text-align: left;
      cursor: pointer;
      margin-bottom: 0px;

      @media (max-width: 750px) or ((min-height: 800px) and (min-width: 750px)) or ((min-height: 500px)) {
        width: 100%;
      }
    }
  }

  .inputGroup-name {
    display: flex;
    gap: 25px;

    @media (max-width: 750px) or ((min-height: 800px) and (min-width: 750px)) or ((min-height: 500px)) {
      gap: 5px;
      flex-direction: column;
      margin-bottom: 25px;
      margin-top: 25px;

      input[type="text"] {
        margin-bottom: 0px;
      }
    }
  }

  .input-custom select {
    background-color: #f0faf5;
    height: 55px;
    border: 0.5px solid #0b3c4950;
    border-radius: 7px;
    margin-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    @media (max-width: 750px) or ((min-height: 800px) and (min-width: 750px)) or ((min-height: 500px)) {
      height: 50px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .input-custom input[type="date"] {
    width: 250px;
    font-weight: 600;
    @media (max-width: 750px) or ((min-height: 800px) and (min-width: 750px)) or ((min-height: 500px)) {
      width: 100%;
    }
  }

  .input-custom input[type="number"] {
    width: 250px;
    margin-bottom: 0px;
    margin-top: 15px;
    @media (max-width: 750px) or ((min-height: 800px) and (min-width: 750px)) or ((min-height: 500px)) {
      width: 100%;
    }
  }

  .input-custom input.selected {
    background-color: #70e189;
    border: 1px solid #00e189;
  }

  .input-custom input.full {
    margin-top: 5px;
    width: 100%;
  }

  .input-custom button {
    border-radius: 70px;
    height: 55px;
    width: 150px;
    border: none;
    color: #111111;
    font-size: 16px;
    font-weight: 700;
    background-color: #70e189;
    margin-left: calc(100% - 150px);

    &disabled {
      opacity: 0.5;
    }

    @media (max-width: 750px) or ((min-height: 800px) and (min-width: 750px)) or ((min-height: 500px)) {
      height: 42px;
      width: 100%;
      margin-left: 0;
    }
  }
`;

const StyledComplete = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 50px 30px 50px;
  background-color: #ffffff;
  border-radius: 10px;
  border: 0.5px solid #0b3c4950;
  width: 90%;
  max-width: 775px;
  min-height: 368px;
  color: #111111;
  gap: 20px;
  font-size: 16px;

  @media (max-width: 750px) or ((min-height: 800px) and (min-width: 750px)) or ((min-height: 500px)) {
    padding: 30px;
    gap: 13px;
    font-size: 14px;
  }

  h3 {
    color: #111111;
    font-size: 26px;
    @media (max-width: 750px) or ((min-height: 800px) and (min-width: 750px)) or ((min-height: 500px)) {
      font-size: 20px;
    }
  }

  p {
    text-align: center;
  }

  img {
    border-radius: 4px;
    width: 95%;
    max-width: 505px;
  }
`;

const tabs = [
  {
    title: "Start",
    type: QuoteInfoType.Start,
    items: [
      {
        title: "Hi! Let's start with a few questions",
        subtitle: "Do you have any current debt consolidation loans currently?",
        type: "options",
        options: ["Yes", "No"],
      },
    ],
  },
  {
    title: "About you",
    type: QuoteInfoType.About,
    items: [
      {
        title: "What's your name?",
        type: "name",
        placeholders: ["First name", "Last name"],
        values: ["", ""],
      },
      {
        title: "Your age",
        subtitle: "Please enter your age",
        type: "age",
        placeholder: "MM/DD/YYYY",
      },
      {
        title: "Select your state",
        value: "",
        type: "state",
      },
      {
        title: "What is your individual annual income?",
        footer: "Enter your before tax income",
        placeholder: "Income",
        type: "income",
      },
      {
        title: "How much are you looking to borrow?",
        footer: "Enter an amount between $5,000 and $100,000",
        placeholder: "Loan amount",
        type: "loan",
      },
    ],
  },
  {
    title: "Quote Information",
    type: QuoteInfoType.Quote,
    items: [
      {
        title: "How are you planning on using these funds?",
        subtitle: "Select all that apply",
        type: "multi-select",
        options: [
          {
            text: "Credit card pay off",
          },
          {
            text: "Loan consolidation",
          },
          {
            text: "Home improvement",
          },
          {
            text: "Major purchase",
          },
          {
            text: "Other",
          },
        ],
      },
      {
        type: "complete",
        contents: [
          {
            title: "Email address",
            placeholder: "Enter your email address",
            value: "",
          },
          {
            title: "Phone number",
            placeholder: "(___) ___ ____",
            value: "",
          },
        ],
        nextButtonLabel: "GET QUOTE",
      },
    ],
  },
];

export default function DebitPage() {
  useTitle("Debit - Simple Debt Fix");

  const [sectionIndex, setSectionIndex] = useState(0);
  const [blockIndex, setBlockIndex] = useState(0);
  const [allTabItem, setAllTabItem] = useState(tabs);

  const generateQuoteInfo = (item) => {
    // console.log(item);
    if (item.contents) {
      let quoteInfos = "";
      for (let index = 0; index < item.contents.length; index++) {
        const quoteInfo = generateQuoteInfo(item.contents[index]);
        quoteInfos += quoteInfo;
      }

      return quoteInfos;
    }

    const question = item.title || item.subtitle || "";
    let answer = item.value ?? "";

    if (item.type === "age" && answer) {
      answer = new Date(answer).toLocaleString("en-US", {
        dateStyle: "long",
      });
    } else if (!answer && item.options) {
      let answers = [];
      for (let optionInx in item.options) {
        let option = item.options[optionInx];
        // console.log(option);
        if (option.isSelected) answers.push(option.text);
      }

      answer = answers.join(", ");
    }
    return `${question}${question.trim().endsWith("?") ? "" : ":"} ${answer}\n`;
  };

  const sendQuote = async () => {
    if (sectionIndex < tabs.length - 1) return;

    let data = "";
    for (let quoteSectionInx in allTabItem) {
      const quoteSectionItems = allTabItem[quoteSectionInx].items;
      for (let sectionItemInx in quoteSectionItems) {
        data += generateQuoteInfo(quoteSectionItems[sectionItemInx]);
      }
    }

    console.log(data);
    // console.log(allTabItem[1].items[0].value);
    // return;

    try {
      const response = await fetch(
        "https://api.emailjs.com/api/v1.0/email/send",
        {
          method: "POST",
          mode: "cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "same-origin", // include, *same-origin, omit
          headers: {
            "Content-Type": "application/json",
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          redirect: "follow", // manual, *follow, error
          referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          body: JSON.stringify({
            service_id: "service_ll4xobj",
            template_id: "template_hiixa2l",
            user_id: "uvOl84J1_grxU87Tp",
            template_params: {
              message: data,
              to_name: "SimpleDebtFix",
              from_name: allTabItem[1].items[0].value,
            },
          }),
        }
      );
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const buildDetailQuestion = () => {
    const item = allTabItem[sectionIndex].items[blockIndex];

    return (
      <StyledQuestionDetail>
        <h3>{item.title}</h3>
        {item.subtitle && <p className="subtitle">{item.subtitle}</p>}

        <div className="input-custom">
          {item.type === "options" && (
            <div className="inputGroup-options">
              {item.options.map((_, index) => (
                <input
                  key={index}
                  type="button"
                  onClick={() => {
                    const allTabItemCopy = { ...allTabItem };
                    const copyItem =
                      allTabItemCopy[sectionIndex].items[blockIndex];
                    copyItem.enabled = true;
                    copyItem.value = item.options[index];
                    setAllTabItem(allTabItemCopy);
                  }}
                  className={
                    item.value === item.options[index] ? "selected" : ""
                  }
                  value={item.options[index]}
                />
              ))}
            </div>
          )}

          {item.type === "name" && (
            <div className="inputGroup-name">
              {item.placeholders.map((_, index) => (
                <input
                  key={index}
                  type="text"
                  onChange={(e) => {
                    const allTabItemCopy = { ...allTabItem };
                    const copyItem =
                      allTabItemCopy[sectionIndex].items[blockIndex];
                    copyItem.values[index] = e.target.value;
                    copyItem.enabled = copyItem.values.every((v) => v !== "");
                    copyItem.value = copyItem.values.join(" ");
                    setAllTabItem(allTabItemCopy);
                  }}
                  value={item.values[index]}
                  placeholder={item.placeholders[index]}
                />
              ))}
            </div>
          )}

          {item.type === "state" && (
            <select
              value={item.value}
              onChange={(e) => {
                const allTabItemCopy = { ...allTabItem };
                const copyItem = allTabItemCopy[sectionIndex].items[blockIndex];
                copyItem.value = e.target.value;
                copyItem.enabled = copyItem.value !== "";
                setAllTabItem(allTabItemCopy);
              }}
            >
              <option value="">State</option>
              {US_STATES.map((state, index) => (
                <option key={index} value={state.value}>
                  {state.label}
                </option>
              ))}
            </select>
          )}

          {item.type === "income" && (
            <input
              type="number"
              onChange={(e) => {
                const allTabItemCopy = { ...allTabItem };
                const copyItem = allTabItemCopy[sectionIndex].items[blockIndex];
                copyItem.value = e.target.value;
                copyItem.enabled = copyItem.value !== 0;
                setAllTabItem(allTabItemCopy);
              }}
              value={item.value}
              placeholder={item.placeholder}
            />
          )}

          {item.type === "loan" && (
            <input
              type="number"
              onChange={(e) => {
                const allTabItemCopy = { ...allTabItem };
                const copyItem = allTabItemCopy[sectionIndex].items[blockIndex];
                copyItem.value = e.target.value;
                copyItem.enabled =
                  copyItem.value !== 0 &&
                  copyItem.value >= 5000 &&
                  copyItem.value <= 100000;
                setAllTabItem(allTabItemCopy);
              }}
              value={item.value}
              placeholder={item.placeholder}
            />
          )}

          {item.type === "multi-select" && (
            <div>
              <div className="inputGroup-select">
                {item.options.map((_, index) => (
                  <input
                    key={index}
                    type="button"
                    onClick={(e) => {
                      const allTabItemCopy = { ...allTabItem };
                      const copyItem =
                        allTabItemCopy[sectionIndex].items[blockIndex];
                      copyItem.options[index].isSelected =
                        !copyItem.options[index].isSelected;
                      copyItem.enabled = copyItem.options.some(
                        (v) => v.isSelected
                      );
                      setAllTabItem(allTabItemCopy);
                    }}
                    value={item.options[index].text}
                    className={item.options[index].isSelected ? "selected" : ""}
                  />
                ))}
              </div>
            </div>
          )}

          {item.type === "complete" && (
            <div style={{ marginBottom: 20 }}>
              <h3>{item.contents[0].title}</h3>
              {item.contents[0].value !== "" &&
                !validator.isEmail(item.contents[0].value) && (
                  <p style={{ color: "red" }}>Invalid Email</p>
                )}
              <input
                type="text"
                className="full"
                onChange={(e) => {
                  const allTabItemCopy = { ...allTabItem };
                  const copyItem =
                    allTabItemCopy[sectionIndex].items[blockIndex];
                  copyItem.contents[0].value = e.target.value ?? "";
                  copyItem.enabled =
                    copyItem.contents[1].value.length > 7 &&
                    copyItem.contents[1].value.length < 12 &&
                    validator.isEmail(copyItem.contents[0].value);
                  setAllTabItem(allTabItemCopy);
                }}
                placeholder={item.contents[0].placeholder}
                value={item.contents[0].value}
              />
              <h3>{item.contents[1].title}</h3>
              <input
                type="number"
                className="full"
                onChange={(e) => {
                  const allTabItemCopy = { ...allTabItem };
                  const copyItem =
                    allTabItemCopy[sectionIndex].items[blockIndex];
                  copyItem.contents[1].value = e.target.value ?? "";
                  copyItem.enabled =
                    copyItem.contents[1].value.length > 7 &&
                    copyItem.contents[1].value.length < 12 &&
                    validator.isEmail(copyItem.contents[0].value);
                  setAllTabItem(allTabItemCopy);
                }}
                placeholder={item.contents[1].placeholder}
                value={item.contents[1].value}
              />
            </div>
          )}
        </div>

        {item.type === "age" && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              minDate={dayjs("1900-01-01")}
              maxDate={dayjs("2019-12-31")}
              openTo="year"
              onChange={(e) => {
                const allTabItemCopy = { ...allTabItem };
                const copyItem = allTabItemCopy[sectionIndex].items[blockIndex];
                copyItem.value = e;
                copyItem.enabled = true;
                setAllTabItem(allTabItemCopy);
              }}
            />
          </LocalizationProvider>
        )}

        {item.footer && <p className="subtitle">{item.footer}</p>}

        <div className="input-custom">
          <button
            disabled={item.enabled !== true}
            onClick={() => {
              if (blockIndex < tabs[sectionIndex].items.length - 1) {
                setBlockIndex((value) => value + 1);
              } else if (sectionIndex < tabs.length) {
                if (sectionIndex === tabs.length - 1) {
                  sendQuote();
                }
                setSectionIndex((value) => value + 1);
                setBlockIndex(0);
              }
            }}
          >
            {item.type === "complete" ? "GET QUOTES" : "Next"}
          </button>
        </div>
      </StyledQuestionDetail>
    );
  };

  const buildComplete = () => {
    return (
      <StyledComplete>
        <h3>Complete!</h3>
        <img src={require("./complete.png")} alt="" />
        <h3>Thanks!</h3>
        <p>
          One of our insurance partners will be in contact with you shortly with
          more information.
        </p>
      </StyledComplete>
    );
  };

  const { width, height } = useWindowDimensions();
  const isMobile = width < 750 || height < 750;

  return (
    <div className="debit-page">
      <StyledHeader>
        <Link to="/">SimpleDebtFix.com</Link>
        <AutoTab
          tabs={tabs}
          sectionIndex={sectionIndex}
          onTabSelected={(index) => {
            console.log("onTabSelected: " + index);
            if (index !== sectionIndex) {
              if (index > 0) {
                if (
                  allTabItem[index - 1].items.some((item) => {
                    console.log(
                      "value => " + item.type + " fillState => " + item.enabled
                    );
                    return item.enabled !== true;
                  })
                )
                  return;
              }

              setSectionIndex(index);
              setBlockIndex(0);
            }
          }}
        />
      </StyledHeader>
      <StyledMain>
        <StyledMainSection>
          {sectionIndex <= tabs.length - 1
            ? buildDetailQuestion()
            : buildComplete()}
          <Footer className={isMobile ? "footer-mobile" : "footer-desktop"} />
        </StyledMainSection>
      </StyledMain>
    </div>
  );
}
