import { useState } from "react"
import "./CreditPage.scss"
import { useTitle } from 'react-use'
import Footer from "../../components/Footer/Footer"
import Navbar from "../../components/Navbar/Navbar"

export default function CreditPage() {
    const [zipCode, setZipCode] = useState("")
    useTitle("Repair your credit - Simple Debt Fix")


    return (
        <>
            <Navbar />
            <div className="credit-page">
                <div className="banner" />
                <div className="content">
                    <label className="label-1" htmlFor="input">
                        Credit rating need some love?<br />
                        Fix your credit rating now!<br />
                    </label>
                    <form className="form-1" >
                        <input
                            className="input-1"
                            value={zipCode}
                            onChange={(e) => setZipCode(e.target.value)}
                            type="text"
                            placeholder="Enter your zip code" id="input" />
                        <button disabled={zipCode.length < 4} onClick={(e) => {
                            e.preventDefault()
                            window.open("https://www.shareasale.com/r.cfm?b=520260&u=3923124&m=49614", "_blank")
                        }} className="button-1" type="submit">Start Now!</button>
                    </form>
                </div>
                <Footer className="footer" />
            </div>
        </>
    )
}