import React from "react"
import "./PrivacyPage.scss"
import { useTitle } from 'react-use'
import PolicesData from "./data.json"
import Footer from "../../components/Footer/Footer"
import Navbar from "../../components/Navbar/Navbar"

export default function PrivacyPage() {
    useTitle("Privacy Policy - Simple Debt Fix")

    return (
        <>
            <Navbar />
            <div className="privacy-page" >
                <div className="privacy-policy-container">
                    <label className="title-label">{PolicesData.title}</label>
                    <label className="sub-title-label">{PolicesData.subTitle}</label>
                    <div className="page-content">
                        <div className="desc">
                            {PolicesData.description.map((item, index) => (
                                <><p key={`policy-description-${index}`}>{item} </p> <br /></>
                            ))}
                        </div>
                        <div className="breakLine"></div>
                        {PolicesData.policy.map((policy, index) => (
                            <div key={`policy-item-${index}`}>
                                <p className="question"><b>{policy.question}</b></p>
                                {policy.answer.map((answer, index) => {
                                    if (typeof (answer) === "object") {
                                        return (answer.map((bullet, index) => (
                                            <ul className="bulletItem" key={`policy-bullet-${index}`}>
                                                <li dangerouslySetInnerHTML={{ __html: bullet }} />
                                            </ul>
                                        ))
                                        )
                                    }
                                    else {
                                        return <p key={`policy-answer-${index}`} dangerouslySetInnerHTML={{ __html: answer }}></p>
                                    }
                                })}
                                <div className="breakLine" />
                            </div>
                        ))}
                        <p className="question"><b>Questions?</b></p>
                        <p>If you have any questions or comments about this Privacy Policy, contact us through the <a href="mailto:contact@simpledebtfix.com">contact@simpledebtfix.com</a>.</p>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}