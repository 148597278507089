import { useState } from "react";
import "./DebtPage.scss";
import { useTitle } from "react-use";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import Navbar from "../../components/Navbar/Navbar";

export default function DebtPage() {
  const [zipCode, setZipCode] = useState("");
  useTitle("Fix Your Debt - Simple Debt Fix");
  const navigate = useNavigate();
  const isKeyboardOpen = useDetectKeyboardOpen();

  if (isKeyboardOpen) {
    window.scroll({
      top: -50,
      left: 0,
      behavior: "smooth",
    });
  }

  return (
    <>
      <Navbar />
      <div scroll="no" className="debt-page">
        <div className="banner" />
        <div className="content">
          <label className="label-1" htmlFor="input">
            Stop worrying, <br />
            start living!
            <br />
            Fix your debt today.
            <br />
          </label>
          <form className="form-1">
            <input
              className="input-1"
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
              type="text"
              placeholder="Enter your zip code"
              id="input"
            />
            <button
              disabled={zipCode.length < 4}
              onClick={(e) => {
                e.preventDefault();
                navigate("/debit");
              }}
              className="button-1"
              type="submit"
            >
              Start Now!
            </button>
          </form>
        </div>

        <Footer className="footer" />
      </div>
    </>
  );
}
