import * as React from "react";

function StartIcon(props) {
  return (
    <svg
      width={45}
      height={45}
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21 35C28.732 35 35 28.732 35 21C35 13.268 28.732 7 21 7C13.268 7 7 13.268 7 21C7 28.732 13.268 35 21 35Z"
        fill="#0B3C49"
      />
      <path
        d="M21 42C32.598 42 42 32.598 42 21C42 9.40202 32.598 0 21 0C9.40202 0 0 9.40202 0 21C0 32.598 9.40202 42 21 42Z"
        fill="#0B3C49"
      />
      <path
        d="M25.8775 21.3107C26.4302 20.9115 26.4302 20.0885 25.8775 19.6893L19.5855 15.1451C18.9242 14.6675 18 15.14 18 15.9558V25.0442C18 25.86 18.9242 26.3325 19.5855 25.8549L25.8775 21.3107Z"
        fill="white"
      />
      <path
        d="M21 -9.17939e-07C24.649 -1.07744e-06 28.235 0.950812 31.4046 2.75874C34.5743 4.56666 37.2181 7.16929 39.0756 10.3101C40.9331 13.451 41.9401 17.0216 41.9974 20.6701C42.0547 24.3187 41.1604 27.9192 39.4024 31.1168L37.1941 29.9028C38.7411 27.0889 39.5282 23.9204 39.4777 20.7097C39.4273 17.499 38.5411 14.3568 36.9065 11.5929C35.2719 8.82897 32.9453 6.53866 30.1561 4.94769C27.3668 3.35671 24.2111 2.52 21 2.52L21 -9.17939e-07Z"
        fill="#0B3C49"
      />
    </svg>
  );
}

export default StartIcon;
