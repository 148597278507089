import "./NoOfferPage.scss"
import { useTitle } from 'react-use'
import Footer from "../../components/Footer/Footer"
import Navbar from "../../components/Navbar/Navbar"

export default function NoOfferPage() {
    useTitle("Fix Your Debt - Simple Debt Fix")

    return (
        <>
            <Navbar />
            <div scroll="no" className="no-offer-page">
                <div className="banner" />
                <label className="label-1" htmlFor="input">
                    Sorry.. <br />
                    No available offers in your area right now.
                </label>

                <Footer className="footer" />
            </div>
        </>
    )
}